<template>
  <div class="check_per">
    <h1 v-if="access != '' && access">Camera access forbidden</h1>
    <Loader :show-loader="isDataLoading" :isFullPage="true" />
    <!-- <span @click="checkQuestion()">click me</span> -->
  </div>
</template>

<script>
import credentials from "@/credentials";
import Loader from "@/components/Loader";
import { mapGetters, mapActions } from "vuex";

var checkAppStatus

export default {
  name: "CheckPermission",
  data() {
    return {
      title: "hello",
      isDataLoading: false,
      access: "",
      doneQuestion:[],
      remainingQuestions:[],
      questionDetail:{}
    };
  },
  components: {
    Loader
  },
  beforeMount() {
    this.apiKey = credentials.API_KEY;
  },
  computed: {
    ...mapGetters(["allQuestions", 'questionSetWithChild', 'questionVideoArr'])
  },
  async mounted() {
    // await this.getQuestion();
    // return
    this.isDataLoading = true;
    // this.$root.$on('save_question', this.saveQuestion)
    // this.$root.$on('update_question', this.onchangeQuestion)
    
    navigator.mediaDevices
      .getUserMedia({ video: true, camera: true, audio: true, microphone: true })
      .then(async mediaStream => {
        // this.allDescendants(this.allQuestions)
        //if (this.allQuestions.length == 0) {
          if(this.allQuestions.length == 0) {
            await this.getQuestion();
            console.log('this.allQuestions', this.allQuestions)
            if(this.allQuestions.length === 0) {
              this.$toast.open({
                message: "No interview is assigned to you, please consult with HR.",
                type: "error"
              });
              // console.log(mediaStream)
              this.$store.commit('resetQuestionData')
              await this.signOut()
              localStorage.removeItem("vuex");
              this.$router.push("/");
              return
            }
            // await  this.checkQuestion()

          } else{
          //  await  this.checkQuestion()
          }
          console.log('this.questionVideoArr.length', this.questionVideoArr.length)


          if (this.questionVideoArr.length == 0) {
            this.questionDetail = this.allQuestions[0];
            this.$store.commit("setquestionDetail", this.questionDetail);
            // await this.$root.$emit('save_question', this.questionDetail)
            this.saveQuestion(this.questionDetail)
          }
          //
       // }
        this.access = false;
        this.$router.push("/questions");
      })
      .catch(error => {
        console.log(error)
        this.access = true;
        this.$toast.open({
          message: "Camera access forbidden",
          type: "error"
        });
        this.isDataLoading = false;
      });

    // if (this.access != "" && !this.access) {
    //   await this.getQuestion();
    //   this.$router.push("/questions");
    // }
    

  },
  created(){
      // document.addEventListener('focus', this.windowState, { once: true })
  },
  methods: {
    ...mapActions(["getQuestion",'signOut']),
    recorderCameraUnresponsive(verifiedVideo) {
      console.log("camera unresponsive");
    },
    onchangeQuestion(data) {
      const results = this.allQuestions.filter(({ que_id: id1 }) => !this.questionVideoArr.some(({ que_id: id2 }) => id2 === id1));
      const remaningquestion = this.questionSetWithChild.filter(({ que_id: id1 }) => !this.questionVideoArr.some(({ que_id: id2 }) => id2 === id1));

      if(results.length == 0 && remaningquestion.length == 0) {
        this.$router.push("/questions");
        return;
      }else{
        if(data != undefined){
          // this.$root.$emit('save_question', data)
           this.saveQuestion(data)
        }

      }
    },
    async saveQuestion2(item) {

    },
    async saveQuestion(item) {
          this.questionDetail = item
          const qdata = item
          let checkIsskipped = item.auto_skipped == 1 || item.is_skipped == 1 || item.system_skipped == 1 ? 1 : 0
          console.log('checkIsskipped', checkIsskipped)
          if(((item.video_id != '' && item.video_id != null) || checkIsskipped == 1)   ||  item.viewed_at != null ){
              console.log('item', item)
              this.$store.dispatch("setVideo", item);
              let qIndex = this.questionSetWithChild.findIndex(x => x.que_id === this.questionDetail.que_id);
              let index = qIndex;
              let cQ;
 
              if (this.questionDetail.childQuestions.length > 0 && checkIsskipped != 1) {
                if (qIndex == -1) {
                  index = this.questionSetWithChild.length;
                }

                this.checkChildQuestion(qdata, index);
                if (qIndex == -1) {
                  cQ = this.questionSetWithChild[index];
                } else {
                  cQ = this.questionSetWithChild[index + 1];
                }
                this.questionDetail =cQ
                if(cQ) {
                  this.$store.commit("setquestionDetail", cQ);
                  // this.$root.$emit('update_question', cQ)
                  this.onchangeQuestion(cQ)
                    console.log('1', cQ.que_id)
                }
              } else {
                const remaningquestion = this.questionSetWithChild.filter(
                  ({ que_id: id1 }) =>
                    !this.questionVideoArr.some(({ que_id: id2 }) => id2 === id1)
                );
                // console.log('remaningquestion...', remaningquestion)
                if (remaningquestion.length > 0) {
                  cQ = remaningquestion[0]
                  // cQ = this.questionSetWithChild[this.questionVideoArr.length];
                  if(cQ) {
                    this.$store.commit("setquestionDetail", cQ);
                    // this.$root.$emit('update_question', cQ)
                     this.onchangeQuestion(cQ)
                    console.log('2',cQ.que_id)
                  }

                } else {
                  const results = this.allQuestions.filter(
                    ({ que_id: id1 }) =>
                      !this.questionVideoArr.some(({ que_id: id2 }) => id2 === id1)
                  );
                  if (results.length > 0) {
                    cQ = results[0];
                    this.$store.commit("setquestionDetail", cQ);

                    // this.$root.$emit('update_question', cQ) 
                     this.onchangeQuestion(cQ)
                    console.log( '3', cQ.que_id)
                  } else {
                    console.log("all questions done");
                    this.$router.push("/questions");
                    return;
                  }
                }
              }
          }
    },
    checkQuestion() {
      const remainingQuestionsNew = this.allQuestions.filter(item => {
          if(item.video_id == null && item.auto_skipped == null && item.is_skipped == null && item.system_skipped == null && item.viewed_at == null ) {
            return item
          }
      })
      const checkchildqsn = this.allQuestions.filter(({ que_id: id1 }) => !remainingQuestionsNew.some(({ que_id: id2 }) => id2 === id1));

      checkchildqsn.forEach(item => {
          console.log('else')
          if(item.video_id == null && item.auto_skipped == null && item.is_skipped == null && item.system_skipped == null && item.viewed_at == null ) {
            console.log('item')
            this.remainingQuestions.push(item)
          } else {
            this.$store.dispatch("setVideo", item);

            if(item.childCount > 0) {
              this.checkChildArr(item.childQuestions)
            }
          }
      })
    },
    checkChildArr(checkChildrenArr) {
      console.log('checkChildrenArr', checkChildrenArr)
      checkChildrenArr.forEach(item => {
        if(item.video_id == null && item.auto_skipped == null && item.is_skipped == null  && item.viewed_at == null) {
            console.log('item')
            const index =  this.questionSetWithChild.length
              // this.$store.commit( "setInterviewQuestionCount",1);
              this.$store.commit("setInterviewQuestion", {
              data: item.childQuestions,
              index: index
            });
        } else {
         const index = this.questionSetWithChild.length
            this.$store.dispatch("setVideo", item);
            // this.$store.commit("setInterviewQuestionCount",1);
            this.$store.commit("setInterviewQuestion", {
              data: item.childQuestions,
              index: index
            });
            if(item.childCount > 0) {
              this.checkChildArr(item.childQuestions)
            }
        }
      })
    },
    checkChildQuestion(qdata, index = 0) {
      if (qdata.childQuestions.length > 0) {
        this.$store.commit(
          "setInterviewQuestionCount",
          qdata.childQuestions.length
        );
        this.$store.commit("setInterviewQuestion", {
          data: qdata.childQuestions,
          index: index + 1
        });
      } else {
        this.$store.commit("setInterviewQuestionCount", 1);
        this.$store.commit("setInterviewQuestion", {
          data: qdata,
          index: index + 1
        });
      }
    },
    checkAlgo(){

    },
    removeTest(child){
      if(hasClass(child, "lbExclude")){
          child.parentNode.removeChild(child);
      }
    },
    allDescendants (node) {
        for (var i = 0; i < node.childQuestions.length; i++) {
          var child = node.childQuestions[i];
          this.allDescendants(child);
          this.removeTest(child);
        }
    }
  }
};
</script>
